
export const FETCH_COMPETITIONS_START = "FETCH_COMPETITIONS_START";
export const FETCH_COMPETITIONS_SUCCESS = "FETCH_COMPETITIONS_SUCCESS";
export const FETCH_COMPETITIONS_FAIL = "FETCH_COMPETITIONS_FAIL";

export const ADD_FORM_COMPETITION_START = "ADD_FORM_COMPETITION_START";
export const ADD_COMPETITION_START = "ADD_COMPETITION_START";
export const ADD_COMPETITION_SUCCESS = "ADD_COMPETITION_SUCCESS";
export const ADD_COMPETITION_FAIL = "ADD_COMPETITION_FAIL";

export const EDIT_COMPETITION_START = "EDIT_COMPETITION_START";
export const EDIT_COMPETITION_SUCCESS = "EDIT_COMPETITION_SUCCESS";
export const EDIT_COMPETITION_FAIL = "EDIT_COMPETITION_FAIL";
export const EDIT_FORM_DATA_COMPETITION = "EDIT_FORM_DATA_COMPETITION";

export const DELETE_COMPETITION_START = "DELETE_COMPETITION_START";
export const DELETE_COMPETITION_SUCCESS = "DELETE_COMPETITION_SUCCESS";
export const DELETE_COMPETITION_FAIL = "DELETE_COMPETITION_FAIL";

export const ERROR_CLEAR = "ERROR_CLEAR";