// import React, { useEffect, useState } from "react";
// import {
//   useDispatch,
//   connect,
//   useSelector
// } from "react-redux";
// import {
//   Modal,
//   Button,
//   ModalHeader,
//   ModalBody,
//   UncontrolledAlert,
//   Col,
//   Row,
//   Label,
// } from "reactstrap";
// import { AvForm, AvField } from "availity-reactstrap-validation";
// import { editCompetitionStart, editFormDataCompetition } from "store/ibCompetition/actions";
// import { MONTHS } from "common/data/dropdowns";
// import AvFieldSelect from "components/Common/AvFieldSelect";
// import { validateFile } from "pages/Transactions/Crypto/AddDepositForm";
// import Loader from "components/Common/Loader";

// function CompetitionEditModal(props) {
//   const { open, selectedCompetition = {}, onClose, getData } = props;
//   const dispatch = useDispatch();
  
//   // Initialize prizes state with selected competition's prizes or empty array
//   const [prizes, setPrizes] = useState(selectedCompetition.prizes || [""]);
  
//   // Initialize images state for file inputs
//   const [images, setImages] = useState([]);
//   const [existingImages, setExistingImages] = useState(selectedCompetition.images || []);

//   // Update state when selectedCompetition changes
//   useEffect(() => {
//     setPrizes(selectedCompetition.prizes || [""]);
//     setExistingImages(selectedCompetition.images || []);
//     setImages([]);
//   }, [selectedCompetition]);

//   // Function to add a new prize field
//   const addPrizeField = () => {
//     setPrizes([...prizes, ""]);
//   };

//   // Function to remove a prize field
//   const removePrizeField = (index) => {
//     const newPrizes = prizes.filter((_, i) => i !== index);
//     setPrizes(newPrizes);
//   };

//   // Handle change for each prize field
//   const handlePrizeChange = (index, value) => {
//     const newPrizes = [...prizes];
//     newPrizes[index] = value;
//     setPrizes(newPrizes);
//   };

//   // Function to add new file input field
//   const addImageField = () => {
//     if (images.length + existingImages.length < 4) {
//       setImages([...images, null]);
//     }
//   };

//   // Function to remove a file input field
//   const removeImageField = (index) => {
//     setImages(images.filter((_, i) => i !== index));
//   };

//   // Function to remove an existing image
//   const removeExistingImage = (index) => {
//     setExistingImages(existingImages.filter((_, i) => i !== index));
//   };

//   // Handle file selection
//   const handleFileChange = (index, file) => {
//     const newImages = [...images];
//     newImages[index] = file;
//     setImages(newImages);
//   };

//   const handleEditCompetition = (e, v) => {
//     e.preventDefault();
//     delete v.prizes;
//     delete v.images;

//     // Check if there are new images or if existing images were removed
//     if (images.length > 0 || existingImages.length !== selectedCompetition.images?.length) {
//       const formData = new FormData();
//       formData.append("id", selectedCompetition._id);
//       formData.append("address", v.address);
//       formData.append("info", v.info);
//       formData.append("competitionDate", v.competitionDate);
      
//       // Add new images
//       images.forEach((image) => {
//         formData.append("images", image);
//       });
      
//       // Add existing images that weren't removed
//       formData.append("existingImages", JSON.stringify(existingImages));
      
//       // Add prizes
//       formData.append("prizes", prizes);
      
//       dispatch(editFormDataCompetition(formData));
//     } else {
//       dispatch(
//         editCompetitionStart({
//           id: selectedCompetition._id,
//           ...v,
//           existingImages,
//           prizes,
//         })
//       );
//     }
//   };

//   useEffect(() => {
//     if (props.editSuccess && open) {
//       onClose();
//       getData();
//     }
//   }, [props.editSuccess]);

//   return (
//     <React.Fragment>
//       <Modal isOpen={open} toggle={onClose} centered={true}>
//         <ModalHeader toggle={onClose} tag="h4">
//           Edit Competition
//         </ModalHeader>
//         <ModalBody>
//           <AvForm
//             className="p-4"
//             onValidSubmit={(e, v) => {
//               handleEditCompetition(e, v);
//             }}
//           >
//             <div className="mb-3">
//               <AvField
//                 name="address"
//                 label="Competition Address"
//                 placeholder="Enter Competition Address"
//                 type="text"
//                 errorMessage="Enter Competition Address"
//                 value={selectedCompetition.address}
//                 validate={{ required: { value: true } }}
//               />
//             </div>

//             <div className="mb-3">
//               <AvField
//                 className="form-control"
//                 name="info"
//                 label="Competition Information"
//                 type="textarea"
//                 rows="5"
//                 placeholder="Enter Competition Information"
//                 validate={{ required: { value: true } }}
//                 style={{ resize: "none" }}
//                 value={selectedCompetition.info}
//               />
//             </div>

//             <div className="mb-3">
//               <AvFieldSelect
//                 name="competitionDate"
//                 type="text"
//                 errorMessage="Competition Date is required"
//                 validate={{ required: { value: true } }}
//                 label="Competition Date"
//                 value={selectedCompetition.competitionDate}
//                 options={MONTHS.map((obj) => ({
//                   label: obj,
//                   value: obj
//                 }))}
//               />
//             </div>

//             <div>
//               <div className="d-flex justify-content-between align-items-center">
//                 <Label>Prizes</Label>
//                 <Button type="button" color="primary" onClick={addPrizeField} className="mt-2">
//                   Add Prize
//                 </Button>
//               </div>
//               {prizes.map((prize, index) => (
//                 <Row key={index} className="mb-2 align-items-center">
//                   <Col md={9}>
//                     <AvField
//                       name={"prizes"}
//                       label={`Prize ${index + 1}`}
//                       type="text"
//                       value={prize}
//                       onChange={(e) => handlePrizeChange(index, e.target.value)}
//                       required
//                     />
//                   </Col>
//                   <Col md={3}>
//                     <i className="bx bx-x-circle text-danger cursor-pointer mt-3" style={{ fontSize: "1.5rem" }} onClick={() => removePrizeField(index)}  />
//                   </Col>
//                 </Row>
//               ))}
//             </div>

//             <div>
//               <div className="d-flex justify-content-between align-items-center">
//                 <Label>Existing Images</Label>
//               </div>
//               {existingImages.length > 0 && (
//                 <Row className="mb-3">
//                   {existingImages.map((imgPath, index) => (
//                     <Col md={3} key={`existing-${index}`} className="mb-2">
//                       <div className="position-relative">
//                         <img 
//                           src={imgPath} 
//                           alt={`Competition Image ${index + 1}`} 
//                           className="img-fluid rounded"
//                           style={{ maxHeight: "80px" }}
//                         />
//                         <i 
//                           className="bx bx-x-circle text-danger position-absolute" 
//                           style={{
//                             top: "5px",
//                             right: "5px",
//                             fontSize: "1.2rem",
//                             cursor: "pointer" 
//                           }}
//                           onClick={() => removeExistingImage(index)}
//                         />
//                       </div>
//                     </Col>
//                   ))}
//                 </Row>
//               )}

//               <div className="d-flex justify-content-between align-items-center mt-3">
//                 <Label>Add New Images</Label>
//                 {(images.length + existingImages.length) < 4 && (
//                   <Button type="button" color="primary" onClick={addImageField} className="mt-2">
//                     Add Image
//                   </Button>
//                 )}
//               </div>

//               {images.map((image, index) => (
//                 <Row key={`new-${index}`} className="mb-3 align-items-center">
//                   <Col md="9">
//                     <Label className="form-label" htmlFor={`image${index}`}>
//                       {`New Image ${index + 1}`}
//                     </Label>
//                     <AvField
//                       type="file"
//                       name={"images"}
//                       className="form-control form-control-md"
//                       errorMessage="Image is required"
//                       onChange={(e) => handleFileChange(index, e.target.files[0])}
//                       validate={{
//                         validate: validateFile(["jpg", "jpeg", "png"], 1000000, image, {
//                           sizeValidationMessage: "The file size is too large",
//                           extensionValidationMessage: "The file extension is not allowed",
//                         })
//                       }}
//                     />
//                   </Col>
//                   <Col md="3">
//                     <i className="bx bx-x-circle text-danger cursor-pointer mt-3" style={{ fontSize: "1.5rem" }} onClick={() => removeImageField(index)}  />
//                   </Col>
//                 </Row>
//               ))}
//             </div>

//             <div className="text-center">
//               {
//                 !props.submitting ?
//                   <Button type="submit" disabled={props.submitting} color="primary">
//                     Update
//                   </Button>
//                   :
//                   <Loader />
//               }
//             </div>
//           </AvForm>
//           {props.editError && (
//             <UncontrolledAlert color="danger">
//               <i className="mdi mdi-block-helper me-2"></i>
//               {props.editError}
//             </UncontrolledAlert>
//           )}
//         </ModalBody>
//       </Modal>
//     </React.Fragment>
//   );
// }

// const mapStateToProps = (state) => ({
//   submitting: state.competitionReducer.submitting,
//   editError: state.competitionReducer.editError,
//   editSuccess: state.competitionReducer.editSuccess,
//   editClearingCounter: state.competitionReducer.editClearingCounter,
// });

// export default connect(mapStateToProps, null)(CompetitionEditModal);

import React, { useEffect, useState } from "react";
import {
  useDispatch,
  connect,
  useSelector
} from "react-redux";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Col,
  Row,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { editCompetitionStart, editFormDataCompetition } from "store/ibCompetition/actions";
import { MONTHS } from "common/data/dropdowns";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { validateFile } from "pages/Transactions/Crypto/AddDepositForm";
import Loader from "components/Common/Loader";

function CompetitionEditModal(props) {
  const { open, selectedCompetition = {}, onClose, getData } = props;
  const dispatch = useDispatch();
  
  // Initialize prizes state with selected competition's prizes or empty array
  const [prizes, setPrizes] = useState(selectedCompetition.prizes || [""]);
  
  // Initialize images state for file inputs
  const [images, setImages] = useState([]);
  const [existingImages, setExistingImages] = useState(selectedCompetition.images || []);

  // Update state when selectedCompetition changes
  useEffect(() => {
    setPrizes(selectedCompetition.prizes || [""]);
    setExistingImages(selectedCompetition.images || []);
    setImages([]);
  }, [selectedCompetition]);

  // Function to add a new prize field
  const addPrizeField = () => {
    setPrizes([...prizes, ""]);
  };

  // Function to remove a prize field
  const removePrizeField = (index) => {
    const newPrizes = prizes.filter((_, i) => i !== index);
    setPrizes(newPrizes);
  };

  // Handle change for each prize field
  const handlePrizeChange = (index, value) => {
    const newPrizes = [...prizes];
    newPrizes[index] = value;
    setPrizes(newPrizes);
  };

  // Function to add new file input field
  const addImageField = () => {
    if (images.length + existingImages.length < 4) {
      setImages([...images, null]);
    }
  };

  // Function to remove a file input field
  const removeImageField = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  // Function to remove an existing image
  const removeExistingImage = (index) => {
    setExistingImages(existingImages.filter((_, i) => i !== index));
  };

  // Handle file selection
  const handleFileChange = (index, file) => {
    const newImages = [...images];
    newImages[index] = file;
    setImages(newImages);
  };

  // Generate years for dropdown (current year and 5 years ahead)
  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i < 6; i++) {
      const year = currentYear + i;
      years.push({
        label: year.toString(),
        value: year.toString() 
      });
    }
    return years;
  };

  const handleEditCompetition = (e, v) => {
    e.preventDefault();
    delete v.prizes;
    delete v.images;

    // Check if there are new images or if existing images were removed
    if (images.length > 0 || existingImages.length !== selectedCompetition.images?.length) {
      const formData = new FormData();
      formData.append("id", selectedCompetition._id);
      formData.append("address", v.address);
      formData.append("info", v.info);
      formData.append("competitionDate", v.competitionDate);
      formData.append("competitionYear", v.competitionYear);
      
      // Add new images
      images.forEach((image) => {
        formData.append("images", image);
      });
      
      // Add existing images that weren't removed
      formData.append("existingImages", JSON.stringify(existingImages));
      
      // Add prizes
      formData.append("prizes", prizes);
      
      dispatch(editFormDataCompetition(formData));
    } else {
      dispatch(
        editCompetitionStart({
          id: selectedCompetition._id,
          ...v,
          existingImages,
          prizes,
        })
      );
    }
  };

  useEffect(() => {
    if (props.editSuccess && open) {
      onClose();
      getData();
    }
  }, [props.editSuccess]);

  return (
    <React.Fragment>
      <Modal isOpen={open} toggle={onClose} centered={true}>
        <ModalHeader toggle={onClose} tag="h4">
          Edit Competition
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="p-4"
            onValidSubmit={(e, v) => {
              handleEditCompetition(e, v);
            }}
          >
            <div className="mb-3">
              <AvField
                name="address"
                label="Competition Address"
                placeholder="Enter Competition Address"
                type="text"
                errorMessage="Enter Competition Address"
                value={selectedCompetition.address}
                validate={{ required: { value: true } }}
              />
            </div>

            <div className="mb-3">
              <AvField
                className="form-control"
                name="info"
                label="Competition Information"
                type="textarea"
                rows="5"
                placeholder="Enter Competition Information"
                validate={{ required: { value: true } }}
                style={{ resize: "none" }}
                value={selectedCompetition.info}
              />
            </div>

            <div className="mb-3">
              <AvFieldSelect
                name="competitionDate"
                type="text"
                errorMessage="Competition Date is required"
                validate={{ required: { value: true } }}
                label="Competition Date"
                value={selectedCompetition.competitionDate}
                options={MONTHS.map((obj) => ({
                  label: obj,
                  value: obj
                }))}
              />
            </div>

            <div className="mb-3">
              <AvFieldSelect
                name="competitionYear"
                type="text"
                errorMessage="Competition Year is required"
                validate={{ required: { value: true } }}
                label="Competition Year"
                value={selectedCompetition.competitionYear}
                options={generateYearOptions()}
              />
            </div>

            <div>
              <div className="d-flex justify-content-between align-items-center">
                <Label>Prizes</Label>
                <Button type="button" color="primary" onClick={addPrizeField} className="mt-2">
                  Add Prize
                </Button>
              </div>
              {prizes.map((prize, index) => (
                <Row key={index} className="mb-2 align-items-center">
                  <Col md={9}>
                    <AvField
                      name={"prizes"}
                      label={`Prize ${index + 1}`}
                      type="text"
                      value={prize}
                      onChange={(e) => handlePrizeChange(index, e.target.value)}
                      required
                    />
                  </Col>
                  <Col md={3}>
                    <i className="bx bx-x-circle text-danger cursor-pointer mt-3" style={{ fontSize: "1.5rem" }} onClick={() => removePrizeField(index)}  />
                  </Col>
                </Row>
              ))}
            </div>

            <div>
              <div className="d-flex justify-content-between align-items-center">
                <Label>Existing Images</Label>
              </div>
              {existingImages.length > 0 && (
                <Row className="mb-3">
                  {existingImages.map((imgPath, index) => (
                    <Col md={3} key={`existing-${index}`} className="mb-2">
                      <div className="position-relative">
                        <img 
                          src={imgPath} 
                          alt={`Competition Image ${index + 1}`} 
                          className="img-fluid rounded"
                          style={{ maxHeight: "80px" }}
                        />
                        <i 
                          className="bx bx-x-circle text-danger position-absolute" 
                          style={{
                            top: "5px",
                            right: "5px",
                            fontSize: "1.2rem",
                            cursor: "pointer" 
                          }}
                          onClick={() => removeExistingImage(index)}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              )}

              <div className="d-flex justify-content-between align-items-center mt-3">
                <Label>Add New Images</Label>
                {(images.length + existingImages.length) < 4 && (
                  <Button type="button" color="primary" onClick={addImageField} className="mt-2">
                    Add Image
                  </Button>
                )}
              </div>

              {images.map((image, index) => (
                <Row key={`new-${index}`} className="mb-3 align-items-center">
                  <Col md="9">
                    <Label className="form-label" htmlFor={`image${index}`}>
                      {`New Image ${index + 1}`}
                    </Label>
                    <AvField
                      type="file"
                      name={"images"}
                      className="form-control form-control-md"
                      errorMessage="Image is required"
                      onChange={(e) => handleFileChange(index, e.target.files[0])}
                      validate={{
                        validate: validateFile(["jpg", "jpeg", "png"], 1000000, image, {
                          sizeValidationMessage: "The file size is too large",
                          extensionValidationMessage: "The file extension is not allowed",
                        })
                      }}
                    />
                  </Col>
                  <Col md="3">
                    <i className="bx bx-x-circle text-danger cursor-pointer mt-3" style={{ fontSize: "1.5rem" }} onClick={() => removeImageField(index)}  />
                  </Col>
                </Row>
              ))}
            </div>

            <div className="text-center">
              {
                !props.submitting ?
                  <Button type="submit" disabled={props.submitting} color="primary">
                    Update
                  </Button>
                  :
                  <Loader />
              }
            </div>
          </AvForm>
          {props.editError && (
            <UncontrolledAlert color="danger">
              <i className="mdi mdi-block-helper me-2"></i>
              {props.editError}
            </UncontrolledAlert>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  submitting: state.competitionReducer.submitting,
  editError: state.competitionReducer.editError,
  editSuccess: state.competitionReducer.editSuccess,
  editClearingCounter: state.competitionReducer.editClearingCounter,
});

export default connect(mapStateToProps, null)(CompetitionEditModal);