import React from "react";
import { 
  Modal, 
  ModalHeader, 
  ModalBody 
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import default styles

function PrizesModal(props) {
  const { selectedCompetition, open, onClose } = props;
  const baseUrl = process.env.REACT_APP_API_CRM_DOMAIN;

  return (
    <Modal isOpen={open} toggle={onClose} centered={true} size={"lg"}>
      <ModalHeader toggle={onClose} tag="h4">
        {props.t("Prizes")}
      </ModalHeader>
      <ModalBody>
        {selectedCompetition.images.length > 0 ? (
          <Carousel
            showThumbs={false}
            autoPlay={true}
            infiniteLoop={true}
            showStatus={false}
            dynamicHeight={true}
          >
            {selectedCompetition.images.map((img, i) => (
              <div key={i}>
                <img
                  // src={`${baseUrl}/assets/${img}`}
                  src={`${img}`}
                  alt={`Prize ${i + 1}`}
                  style={{ 
                    maxHeight: "400px", 
                    objectFit: "contain" 
                  }}
                />
              </div>
            ))}
          </Carousel>
        ) : (
          <p>No images available</p>
        )}
      </ModalBody>
    </Modal>
  );
}

export default withTranslation()(PrizesModal);
