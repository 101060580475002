/* eslint-disable no-case-declarations */

import {
  FETCH_COMPETITIONS_START,
  FETCH_COMPETITIONS_SUCCESS,
  FETCH_COMPETITIONS_FAIL,
  ADD_COMPETITION_START,
  ADD_COMPETITION_SUCCESS,
  ADD_COMPETITION_FAIL,
  DELETE_COMPETITION_START,
  DELETE_COMPETITION_SUCCESS,
  DELETE_COMPETITION_FAIL,
  ERROR_CLEAR,
  ADD_FORM_COMPETITION_START,
  EDIT_COMPETITION_START,
  EDIT_FORM_DATA_COMPETITION,
  EDIT_COMPETITION_SUCCESS,
  EDIT_COMPETITION_FAIL
} from "./actionTypes";

const initialState = {
  competitions: [],
  loading: false,
  submitting: false,
  addSuccess: false,
  addErrorDetails: "",
  editSuccess: false, 
  editError: false,
  editErrorDetails: "",
  clearingCounter: 0,
  editClearingCounter: 0,
  error: "",
  totalDocs: 0,
  hasNextPage: false,
  hasPrevPage: false,
  limit: 10,
  nextPage: null,
  page: 1,
  pagingCounter: 1,
  prevPage: null,
  totalPages: 0
};

const competitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPETITIONS_START:
      return {
        ...state,
        addSuccess: false,
        loading: true,
        error: ""
      };
      
    case FETCH_COMPETITIONS_SUCCESS:
      return {
        ...state,
        competitions: [...action.payload.result.docs],
        totalDocs: action.payload.result.totalDocs,
        hasNextPage: action.payload.result.hasNextPage,
        hasPrevPage: action.payload.result.hasPrevPage,
        limit: action.payload.result.limit,
        nextPage: action.payload.result.nextPage,
        page: action.payload.result.page,
        pagingCounter: action.payload.result.pagingCounter,
        prevPage: action.payload.result.prevPage,
        totalPages: action.payload.result.totalPages,
        loading: false       
      };
      
    case FETCH_COMPETITIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
      
    case ADD_FORM_COMPETITION_START:
    case ADD_COMPETITION_START:
      return {
        ...state,
        addSuccess: false,
        submitting: true,
        error: ""
      };
      
    case ADD_COMPETITION_SUCCESS:
      console.log("ADD_COMPETITION_SUCCESS", action.payload, [action.payload, ...state.competitions]);
      return {
        ...state,
        competitions: [action.payload, ...state.competitions],
        totalDocs: state.totalDocs + 1,
        submitting: false,
        addSuccess: true,
      };
      
    case ADD_COMPETITION_FAIL:
      return {
        ...state,
        addSuccess: false,
        submitting: false,
        error: action.payload.error
      };
      
    case DELETE_COMPETITION_START:
      return {
        ...state,
        submitting: true,
        error: ""
      };
      
    case DELETE_COMPETITION_SUCCESS:
      console.log("ADD_COMPETITION_SUCCESS", action.payload, );
      return {
        ...state,
        competitions: state.competitions.filter(
          competition => competition._id !== action.payload.id
        ),
        totalDocs: state.totalDocs - 1,
        submitting: false
      };
      
    case DELETE_COMPETITION_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.payload.error
      };
      
    case EDIT_COMPETITION_START:
    case EDIT_FORM_DATA_COMPETITION:
      return {
        ...state,
        submitting: true,
        editSuccess: false,
        editError: false,
        editErrorDetails: "",
      };

    case EDIT_COMPETITION_SUCCESS:
      // Find the index of the competition to update
      console.log("EDIT_COMPETITION_SUCCESS", action.payload);
      // const updatedIndex = state.competitions.findIndex(
      //   (comp) => comp._id === action?.payload?.data?._id
      // );
      
      // // Create a new array with the updated competition
      // const updatedCompetitions = [...state?.competitions];
      // if (updatedIndex !== -1) {
      //   updatedCompetitions[updatedIndex] = action?.payload?.data;
      // }
      
      return {
        ...state,
        // competitions: updatedCompetitions,
        submitting: false,
        editSuccess: true,
        editError: false,
        editClearingCounter: state?.editClearingCounter + 1,
      };

    case EDIT_COMPETITION_FAIL:
      return {
        ...state,
        submitting: false,
        editSuccess: false,
        editError: true,
        editErrorDetails: action?.payload,
      };
    case ERROR_CLEAR:
      return {
        ...state,
        error: ""
      };
      
    default:
      return state;
  }
};

export default competitionReducer;
