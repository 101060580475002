import * as axiosHelper from "./api_helper";
import qs from "qs";

export const getCompetitions = async ({ payload })=>{
  const competitions = await axiosHelper.get(`/ib-competitions?${qs.stringify(payload)}`);
  return competitions;
};

export const createCompetition = async (values)=>{
  const result = await axiosHelper.post("/ib-competitions", values);
  if (result.code === 422){
    throw new Error("IB Competitions has failed");
  }
  return result ;
};

export const createFormDataCompetition = async (values)=>{
  const result = await axiosHelper.postFormData("/ib-competitions", values);
  if (result.code === 422){
    throw new Error("IB Competitions has failed");
  }
  return result ;
};


export const updateCompetition = async (id, values) => {
  const result = await axiosHelper.post(`/ib-competitions/${id}`, values);
  if (result.code === 422) {
    throw new Error("IB Competition update has failed");
  }
  return result;
};

export const updateFormDataCompetition = async (id, values) => {
  const result = await axiosHelper.postFormData(`/ib-competitions/${id}`, values);
  if (result.code === 422) {
    throw new Error("IB Competition update has failed");
  }
  return result;
};

export const deleteCompetition = async (id)=>{
  const result = await axiosHelper.del(`/ib-competitions/${id}`);
  if (result.code === 422){
    throw new Error("IB Competitions has failed");
  }
  return result ;
};
