// import React, { useEffect, useState } from "react";
// import {
//   useDispatch, useSelector
// } from "react-redux";
// import { Link } from "react-router-dom";

// import {
//   Row, Col, Card, CardBody, CardTitle, CardHeader 
// } from "reactstrap";

// import {
//   Table, Thead, Tbody, Tr, Th, Td
// } from "react-super-responsive-table";
// import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

// import {
//   fetchTeams, deleteTeam,
// } from "store/teams/actions";
// import CustomPagination from "components/Common/CustomPagination";
// import TableLoader from "components/Common/TableLoader";
// import DeleteModal from "components/Common/DeleteModal";
// import { showSuccessNotification } from "store/notifications/actions";
// import { MetaTags } from "react-meta-tags";
// import AddCompetition from "./AddCompetition";
// import { fetchCompetitionsStart, fetchReportStart } from "store/actions";
// import PrizesModal from "./prizesModal";
// import EditCompetitionModal from "./editCompetitionModal";

// function IBCompetition() {

//   const [editModal, setEditModal] = useState(false);

//   const [deleteModal, setDeleteTeamModal] = useState(false);

//   const [prizesModal, setPrizesModal] = useState(false);
//   const [selectedCompetition, setSelectedCompetition] = useState(false);
//   const [month, setMonth] = useState(false);
//   const [date, setDate] = useState("");

//   console.log("ssssssssssssssss", month, date);

//   const {
//     loading,
//     docs,
//     page,
//     totalDocs,
//     totalPages,
//     hasNextPage,
//     hasPrevPage,
//     limit,
//     nextPage,
//     pagingCounter,
//     prevPage,
//     deleteLoading,
//     deleteClearingCounter,
//     // roles,
//     // editClearingCounter,
//     teamsPermissions
//   } = useSelector((state) => ({
//     loading: state.competitionReducer.loading || false,
//     docs: state.competitionReducer.competitions || [],
//     page: state.competitionReducer.page || 1,
//     totalDocs: state.competitionReducer.totalDocs || 0,
//     totalPages: state.competitionReducer.totalPages || 0,
//     hasNextPage: state.competitionReducer.hasNextPage,
//     hasPrevPage: state.competitionReducer.hasPrevPage,
//     limit: state.competitionReducer.limit,
//     nextPage: state.competitionReducer.nextPage,
//     pagingCounter: state.competitionReducer.pagingCounter,
//     prevPage: state.competitionReducer.prevPage,
//     deleteLoading: state.competitionReducer.deleteLoading,
//     deleteClearingCounter: state.competitionReducer.deleteClearingCounter,
//     // roles: state.competitionReducer.rolesData,
//     clearingCounter: state.competitionReducer.clearingCounter,
//     // editClearingCounter: state.competitionReducer.editClearingCounter,
//     teamsPermissions: state.Profile.teamsPermissions || {},
  
//   }));

//   const {
//     ibDocs,
//     ibLoading,
//     ibPage,
//     ibTotalDocs,
//     ibTotalPages,
//     ibHasNextPage,
//     ibHasPrevPage,
//     ibLimit,
//     ibNextPage,
//     ibPagingCounter,
//     ibPrevPage,
//     ibDeleteLoading
//   } = useSelector((state)=>({
//     ibDocs:state.reportsReducer.docs || [],
//     ibLoading: state.reportsReducer.loading || false,
//     ibPage: state.reportsReducer.page || 1,
//     ibTotalDocs: state.reportsReducer.totalDocs || 0,
//     ibTotalPages: state.reportsReducer.totalPages || 0,
//     ibHasNextPage: state.reportsReducer.hasNextPage,
//     ibHasPrevPage: state.reportsReducer.hasPrevPage,
//     ibLimit: state.reportsReducer.limit,
//     ibNextPage: state.reportsReducer.nextPage,
//     ibPagingCounter: state.reportsReducer.pagingCounter,
//     ibPrevPage: state.reportsReducer.prevPage,
//     ibDeleteLoading: state.reportsReducer.deleteLoading,

//   }) );
//   const { update, delete: deletePermission } = teamsPermissions;
//   const columns = [
//     {
//       text: "ID",
//       dataField: "recordId",
//       sort: true,
//     },
//     {
//       text: "month",
//       dataField: "month",
//       sort: true,
//       formatter: (val) => {
//         return     <div onClick={()=>{
//           setMonth(true);
//           setDate(val.competitionDate);
//         }}>
//           {  val.competitionDate }
//         </div>;

//       },

//     },
//     {
//       text: "Title",
//       dataField: "title",
//       sort: true,
//       formatter: (val) => val.address,
//     },
//     {
//       text: "Information",
//       dataField: "info",
//       sort: true,
//       formatter: (val) => val.info,
//     },
//     {
//       text: "Prizes",
//       dataField: "prizes",
//       sort: true,
//       formatter: (val) => val.prizes.join(","),
//     },
//     {
//       dataField: "images",
//       text: "Images",
//       formatter: (val) => {
//         if (val?.images.length > 0) {
//           return (
//             <div className="text-center">
//               <div
//                 className={"text-success cursor-pointer"}
//               >
//                 <i
//                   className="mdi mdi-eye font-size-20"
//                   id="edittooltip"
//                   onClick={() => {
//                     setPrizesModal(true);
//                     setSelectedCompetition(val);
//                   }}
//                 ></i>
//               </div>
//             </div>
//           );
//         } else {
//           return (
//             <div className="text-center">
//               <div className={"text-muted "}>
//                 <i className="mdi mdi-eye font-size-20" id="edittooltip"></i>
//               </div>
//             </div>
//           );
//         }
//       },
//     },
//     {
//       dataField: "",
//       isDummyField: true,
//       editable: false,
//       text: "Action",
//       formatter: (team) => (
//         <div className="text-center">
//           <Link className={`text-success ${!update ? "d-none" : ""}`} to="#">
//             <i
//               className="mdi mdi-pencil font-size-18"
//               id="edittooltip"
//               onClick={() => {
//                 setSelectedCompetition(team);
//                 setEditModal(true);
//               }}
//             ></i>
//           </Link>
//           <Link className={`text-danger ${!deletePermission ? "d-none" : ""}`} to="#">
//             <i
//               className="mdi mdi-delete font-size-18"
//               id="deletetooltip"
//               onClick={() => { setSelectedCompetition(team); setDeleteTeamModal(true) }}></i>
//           </Link>
//         </div>
//       ),
//     },
//   ];

//   const ibColumns = [
//     {
//       dataField: "rank",
//       text: "Rank",
//       formatter: (val, rowIndex) => rowIndex + 1, // Assigning rank starting from 1
//     },
//     {
//       dataField: "client",
//       text: "Client",
//       formatter: (val) =>
//         val?.customer ? (
//           <Link to={`clients/${val?.customer?._id}/profile`}>
//             {val?.customer
//               ? `${val?.customer?.firstName} ${val?.customer?.lastName}`
//               : "-"}
//           </Link>
//         ) : (
//           "-"
//         ),
//     },
//     {
//       dataField: "rebate",
//       text: "Rebate",
//     },
//     {
//       dataField: "agent",
//       text: "Agent",
//       formatter: (val) =>
//         val?.agent ? `${val.agent?.firstName} ${val.agent?.lastName}` : "-",
//     },

//   ];
//   const [sizePerPage, setSizePerPage] = useState(10);
//   const [currentPage, setcurrentPagePage] = useState(1);
//   const [sizePerPageIb, setSizePerPageIb] = useState(10);
//   const [currentPageIb, setcurrentPageIb] = useState(1);

//   const dispatch = useDispatch();

//   useEffect(() => {
//     loadCompetitions(currentPage, sizePerPage);
//   }, [sizePerPage]);

//   useEffect(() => {
//     loadReport(currentPageIb, sizePerPageIb);
//   }, [month, sizePerPageIb]);
//   const loadReport = (page, limit) => {
//     setcurrentPageIb(page);
//     dispatch(fetchReportStart({
//       month:date,
//       reportType:"ib-summary",
//       page,
//       limit
//     }));
//   };

//   const loadCompetitions = (page, limit) => {
//     setcurrentPagePage(page);
//     dispatch(fetchCompetitionsStart({
//       page,
//       limit,
//     }));
//   };

//   // const loadIbs = dispatch(fetchReportStart({
//   //   month:date;
//   // }))
//   const numPageRows = (numOfRows) => {
//     setSizePerPage(numOfRows);
//   };
//   const numPageRowsIb = (numOfRows) => {
//     setSizePerPageIb(numOfRows);
//   };
//   const deleteTeamHandel = () => {
//     // dispatch(deleteTeam(selectedTeam._id));
//     dispatch(showSuccessNotification("Team deleted Successfully"));
//   };

//   useEffect(() => {
//     if (deleteClearingCounter > 0 && deleteModal) {
//       setDeleteTeamModal(false);
//     }
//   }, [deleteClearingCounter]);

//   return (
//     <React.Fragment>
//       <MetaTags>
//         <title>
//           IB Competition
//         </title>
//       </MetaTags>
//       <div className="page-content">
//         <div className="container-fluid">
//           <h2>IB Competition</h2>
//           <Row>
//             <Col className="col-12">
//               <Card>
//                 <CardHeader className="d-flex justify-content-between  align-items-center">
//                   <CardTitle className="color-primary">
//                     IB Competition ({totalDocs})
//                   </CardTitle>
//                   <AddCompetition />
//                 </CardHeader>
//                 <CardBody>
//                   <div className="table-rep-plugin">
//                     <div
//                       className="table-responsive mb-0"
//                       data-pattern="priority-columns"
//                     >
//                       <Table
//                         id="tech-companies-1"
//                         className="table  table-hover "
//                       >
//                         <Thead className="text-center table-light" >
//                           <Tr>
//                             {columns.map((column, index) =>
//                               <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
//                             )}
//                           </Tr>
//                         </Thead>
//                         <Tbody className="text-center">
//                           {loading && <TableLoader colSpan={6} />}
//                           {!loading && docs.map((row, rowIndex) =>
//                             <Tr key={rowIndex}>
//                               {columns.map((column, index) =>
//                                 <Td key={`${rowIndex}-${index}`}>
//                                   {column.dataField === "rank"
//                                     ? rowIndex + 1 // Generate Rank Dynamically
//                                     : column.formatter
//                                       ? column.formatter(row, rowIndex)
//                                       : row[column.dataField]}
//                                 </Td>
//                               )}
//                             </Tr>
//                           )}
//                         </Tbody>
//                       </Table>
//                       <CustomPagination
//                         totalPages={totalPages}
//                         docs={docs}
//                         sizePerPage={sizePerPage}
//                         page={page}
//                         totalDocs={totalDocs}
//                         hasNextPage={hasNextPage}
//                         hasPrevPage={hasPrevPage}
//                         prevPage={prevPage}
//                         nextPage={nextPage}
//                         limit={limit}
//                         pagingCounter={pagingCounter}
//                         setSizePerPage={numPageRows}
//                         onChange={loadCompetitions}
//                       />
//                     </div>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//           </Row>

 
//           {selectedCompetition && (
//             <PrizesModal open={prizesModal} selectedCompetition={selectedCompetition} onClose={() => setPrizesModal(false)} />
//           )}
//           {<EditCompetitionModal open={editModal} selectedCompetition={selectedCompetition} onClose={() => { setEditModal(false) }} />}
//           {<DeleteModal loading={deleteLoading} onDeleteClick={deleteTeamHandel} show={deleteModal} onCloseClick={() => { setDeleteTeamModal(false) }} />}
//         </div>
//       </div>
//       {month && (
//         <Row>
//           <Col className="col-12">
//             <Card>
      
//               <CardBody>
//                 <div className="table-rep-plugin">
//                   <div
//                     className="table-responsive mb-0"
//                     data-pattern="priority-columns"
//                   >
//                     <Table
//                       id="tech-companies-1"
//                       className="table  table-hover "
//                     >
//                       <Thead className="text-center table-light" >
//                         <Tr>
//                           {ibColumns.map((column, index) =>
//                             <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
//                           )}
//                         </Tr>
//                       </Thead>
//                       <Tbody className="text-center">
//                         {ibLoading && <TableLoader colSpan={6} />}
//                         {!ibLoading && ibDocs.map((row, rowIndex) =>
//                           <Tr key={rowIndex}>
//                             {ibColumns.map((column, index) =>
//                               <Td key={`${rowIndex}-${index}`}>
//                                 {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
//                               </Td>
//                             )}
//                           </Tr>
//                         )}
//                       </Tbody>
//                     </Table>
//                     <CustomPagination
//                       totalPages={ibTotalPages}
//                       docs={ibDocs}
//                       sizePerPage={sizePerPageIb}
//                       page={ibPage}
//                       totalDocs={ibTotalDocs}
//                       hasNextPage={ibHasNextPage}
//                       hasPrevPage={ibHasPrevPage}
//                       prevPage={ibPrevPage}
//                       nextPage={ibNextPage}
//                       limit={ibLimit}
//                       pagingCounter={ibPagingCounter}
//                       setSizePerPage={numPageRowsIb}
//                       onChange={loadReport}
//                     />
//                   </div>
//                 </div>
//               </CardBody>
//             </Card>
//           </Col>
//         </Row>
//       )}
//     </React.Fragment>
//   );
// }
// export default IBCompetition;

import React, { useEffect, useState } from "react";
import {
  useDispatch, useSelector
} from "react-redux";
import { Link } from "react-router-dom";

import {
  Row, Col, Card, CardBody, CardTitle, CardHeader, Button
} from "reactstrap";

import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import DeleteModal from "components/Common/DeleteModal";
import { showSuccessNotification } from "store/notifications/actions";
import { MetaTags } from "react-meta-tags";
import AddCompetition from "./AddCompetition";
import { 
  fetchCompetitionsStart, 
  fetchReportStart, 
  deleteCompetitionStart 
} from "store/actions";
import PrizesModal from "./prizesModal";
import EditCompetitionModal from "./editCompetitionModal";

function IBCompetition() {
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [prizesModal, setPrizesModal] = useState(false);
  const [selectedCompetition, setSelectedCompetition] = useState(null);
  const [month, setMonth] = useState(false);
  const [date, setDate] = useState("");
  const [year, setYear] = useState("");

  const {
    loading,
    submitting,
    docs,
    page,
    totalDocs,
    totalPages,
    hasNextPage,
    hasPrevPage,
    limit,
    nextPage,
    pagingCounter,
    prevPage,
    teamsPermissions
  } = useSelector((state) => ({
    loading: state.competitionReducer.loading || false,
    submitting: state.competitionReducer.submitting || false,
    docs: state.competitionReducer.competitions || [],
    page: state.competitionReducer.page || 1,
    totalDocs: state.competitionReducer.totalDocs || 0,
    totalPages: state.competitionReducer.totalPages || 0,
    hasNextPage: state.competitionReducer.hasNextPage,
    hasPrevPage: state.competitionReducer.hasPrevPage,
    limit: state.competitionReducer.limit,
    nextPage: state.competitionReducer.nextPage,
    pagingCounter: state.competitionReducer.pagingCounter,
    prevPage: state.competitionReducer.prevPage,
    teamsPermissions: state.Profile.teamsPermissions || {},
  }));

  const {
    ibDocs,
    ibLoading,
    ibPage,
    ibTotalDocs,
    ibTotalPages,
    ibHasNextPage,
    ibHasPrevPage,
    ibLimit,
    ibNextPage,
    ibPagingCounter,
    ibPrevPage,
  } = useSelector((state) => ({
    ibDocs: state.reportsReducer.docs || [],
    ibLoading: state.reportsReducer.loading || false,
    ibPage: state.reportsReducer.page || 1,
    ibTotalDocs: state.reportsReducer.totalDocs || 0,
    ibTotalPages: state.reportsReducer.totalPages || 0,
    ibHasNextPage: state.reportsReducer.hasNextPage,
    ibHasPrevPage: state.reportsReducer.hasPrevPage,
    ibLimit: state.reportsReducer.limit,
    ibNextPage: state.reportsReducer.nextPage,
    ibPagingCounter: state.reportsReducer.pagingCounter,
    ibPrevPage: state.reportsReducer.prevPage,
  }));

  const { update, delete: deletePermission } = teamsPermissions;
  
  const columns = [
    {
      text: "ID",
      dataField: "recordId",
      sort: true,
    },
    {
      text: "Date",
      dataField: "competitionDate",
      sort: true,
      formatter: (val) => {
        return (
          <div onClick={() => {
            setMonth(true);
            setDate(val?.competitionDate);
            setYear(val?.competitionYear);
          }}>
            {formatDate(val?.competitionDate, val?.competitionYear)}
          </div>
        );
      },
    },
    {
      text: "Address",
      dataField: "address",
      sort: true,
    },
    {
      text: "Information",
      dataField: "info",
      sort: true,
      formatter: (val) => (
        <div style={{
          maxWidth: "200px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis" 
        }}>
          {val.info}
        </div>
      ),
    },
    {
      text: "Prizes",
      dataField: "prizes",
      sort: true,
      formatter: (val) => val.prizes.join(","),
    },
    // {
    //   text: "Prizes",
    //   dataField: "prizes",
    //   sort: true,
    //   formatter: (val) => {
    //     if (val.prizes && val.prizes.length > 0) {
    //       return (
    //         <Button 
    //           color="link" 
    //           className="p-0" 
    //           onClick={() => {
    //             setSelectedCompetition(val);
    //             setPrizesModal(true);
    //           }}
    //         >
    //           View Prizes ({val.prizes.length})
    //         </Button>
    //       );
    //     }
    //     return "-";
    //   },
    // },
    {
      dataField: "images",
      text: "Images",
      formatter: (val) => {
        if (val?.images && val.images.length > 0) {
          return (
            <div className="text-center">
              <div className={"text-success cursor-pointer"}>
                <i
                  className="mdi mdi-eye font-size-20"
                  id="viewimages"
                  onClick={() => {
                    setPrizesModal(true);
                    setSelectedCompetition(val);
                  }}
                ></i>
              </div>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <div className={"text-muted "}>
                <i className="mdi mdi-eye-off font-size-20" id="noimages"></i>
              </div>
            </div>
          );
        }
      },
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (competition) => (
        <div className="text-center d-flex justify-content-center">
          {update && (
            <Button color="link" className="px-2 py-0 text-success">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={() => {
                  setSelectedCompetition(competition);
                  setEditModal(true);
                }}
              ></i>
            </Button>
          )}
          {deletePermission && (
            <Button color="link" className="px-2 py-0 text-danger">
              <i
                className="mdi mdi-delete font-size-18"
                id="deletetooltip"
                onClick={() => {
                  setSelectedCompetition(competition);
                  setDeleteModal(true);
                }}
              ></i>
            </Button>
          )}
        </div>
      ),
    },
  ];

  const ibColumns = [
    {
      dataField: "rank",
      text: "Rank",
      formatter: (val, rowIndex) => rowIndex + 1, // Assigning rank starting from 1
    },
    {
      dataField: "client",
      text: "Client",
      formatter: (val) =>
        val?.customer ? (
          <Link to={`clients/${val?.customer?._id}/profile`}>
            {val?.customer
              ? `${val?.customer?.firstName} ${val?.customer?.lastName}`
              : "-"}
          </Link>
        ) : (
          "-"
        ),
    },
    {
      dataField: "lotsOpened",
      text: "Total Lots",
      formatter: (val, rowIndex) => val?.lotsOpened / 10000,
    },
    // {
    //   dataField: "agent",
    //   text: "Agent",
    //   formatter: (val) =>
    //     val?.agent ? `${val.agent?.firstName} ${val.agent?.lastName}` : "-",
    // },
  ];

  const [sizePerPage, setSizePerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPageIb, setSizePerPageIb] = useState(10);
  const [currentPageIb, setCurrentPageIb] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    loadCompetitions(currentPage, sizePerPage);
  }, [sizePerPage]);

  useEffect(() => {
    if (month) {
      loadReport(currentPageIb, sizePerPageIb);
    }
  }, [month, sizePerPageIb]);

  const formatDate = (dateStr, yearStr) => {
    // if (!dateStr) return "-";
    return `${yearStr}-${dateStr}`;
    // const date = new Date(dateStr);
    // return date.toLocaleDateString("en-US", {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric"
    // });
  };

  const loadReport = (page, limit) => {
    setCurrentPageIb(page);
    dispatch(fetchReportStart({
      month: date,
      reportType: "ib-summary",
      page,
      limit
    }));
  };

  const loadCompetitions = (page, limit) => {
    setCurrentPage(page);
    dispatch(fetchCompetitionsStart({
      page,
      limit,
    }));
  };

  const numPageRows = (numOfRows) => {
    setSizePerPage(numOfRows);
  };

  const numPageRowsIb = (numOfRows) => {
    setSizePerPageIb(numOfRows);
  };

  const handleDeleteCompetition = () => {
    if (selectedCompetition && selectedCompetition?._id) {
      dispatch(deleteCompetitionStart(selectedCompetition?._id));
      setDeleteModal(false);
    }
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>IB Competition</title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>IB Competition</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between align-items-center">
                  <CardTitle className="color-primary">
                    IB Competition ({totalDocs})
                  </CardTitle>
                  <AddCompetition />
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-hover"
                      >
                        <Thead className="text-center table-light">
                          <Tr>
                            {columns.map((column, index) => (
                              <Th data-priority={index} key={index}>
                                <span className="color-primary">{column.text}</span>
                              </Th>
                            ))}
                          </Tr>
                        </Thead>
                        <Tbody className="text-center">
                          {loading && <TableLoader colSpan={columns.length} />}
                          {!loading && docs?.length === 0 && (
                            <Tr>
                              <Td colSpan={columns.length} className="text-center">
                                No competitions found. Create a new one to get started.
                              </Td>
                            </Tr>
                          )}
                          {!loading && docs?.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                              {columns.map((column, index) => (
                                <Td key={`${rowIndex}-${index}`}>
                                  {column?.dataField === "rank"
                                    ? rowIndex + 1 // Generate Rank Dynamically
                                    : column?.formatter
                                      ? column?.formatter(row, rowIndex)
                                      : row[column?.dataField] || "-"}
                                </Td>
                              ))}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                      <CustomPagination
                        totalPages={totalPages}
                        docs={docs}
                        sizePerPage={sizePerPage}
                        page={page}
                        totalDocs={totalDocs}
                        hasNextPage={hasNextPage}
                        hasPrevPage={hasPrevPage}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        limit={limit}
                        pagingCounter={pagingCounter}
                        setSizePerPage={numPageRows}
                        onChange={loadCompetitions}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {selectedCompetition && (
            <>
              <PrizesModal 
                open={prizesModal} 
                selectedCompetition={selectedCompetition} 
                onClose={() => setPrizesModal(false)} 
              />
              
              <EditCompetitionModal 
                open={editModal} 
                selectedCompetition={selectedCompetition} 
                onClose={() => setEditModal(false)} 
                getData={() => loadCompetitions(currentPage, sizePerPage)} 
              />
              
              <DeleteModal 
                loading={submitting} 
                onDeleteClick={handleDeleteCompetition} 
                show={deleteModal} 
                onCloseClick={() => setDeleteModal(false)} 
                message="Are you sure you want to delete this competition? This action cannot be undone."
              />
            </>
          )}
        </div>
      </div>
      
      {month && (
        <div className="page-content pt-0">
          <div className="container-fluid">
            <Row>
              <Col className="col-12">
                <Card>
                  <CardHeader>
                    <CardTitle className="color-primary">
                      IB Rankings for {formatDate(date, year)}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-2"
                          className="table table-hover"
                        >
                          <Thead className="text-center table-light">
                            <Tr>
                              {ibColumns.map((column, index) => (
                                <Th data-priority={index} key={index}>
                                  <span className="color-primary">{column.text}</span>
                                </Th>
                              ))}
                            </Tr>
                          </Thead>
                          <Tbody className="text-center">
                            {ibLoading && <TableLoader colSpan={ibColumns.length} />}
                            {!ibLoading && ibDocs.length === 0 && (
                              <Tr>
                                <Td colSpan={ibColumns.length} className="text-center">
                                  No ranking data found for this competition date.
                                </Td>
                              </Tr>
                            )}
                            {!ibLoading && ibDocs.map((row, rowIndex) => (
                              <Tr key={rowIndex}>
                                {ibColumns.map((column, index) => (
                                  <Td key={`${rowIndex}-${index}`}>
                                    {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField] || "-"}
                                  </Td>
                                ))}
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                        <CustomPagination
                          totalPages={ibTotalPages}
                          docs={ibDocs}
                          sizePerPage={sizePerPageIb}
                          page={ibPage}
                          totalDocs={ibTotalDocs}
                          hasNextPage={ibHasNextPage}
                          hasPrevPage={ibHasPrevPage}
                          prevPage={ibPrevPage}
                          nextPage={ibNextPage}
                          limit={ibLimit}
                          pagingCounter={ibPagingCounter}
                          setSizePerPage={numPageRowsIb}
                          onChange={loadReport}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default IBCompetition;