
import {
  FETCH_COMPETITIONS_START,
  FETCH_COMPETITIONS_SUCCESS,
  FETCH_COMPETITIONS_FAIL,
  ADD_COMPETITION_START,
  ADD_COMPETITION_SUCCESS,
  ADD_COMPETITION_FAIL,
  DELETE_COMPETITION_START,
  DELETE_COMPETITION_SUCCESS,
  DELETE_COMPETITION_FAIL,
  ERROR_CLEAR,
  ADD_FORM_COMPETITION_START,
  EDIT_COMPETITION_START,
  EDIT_COMPETITION_SUCCESS,
  EDIT_COMPETITION_FAIL,
  EDIT_FORM_DATA_COMPETITION
} from "./actionTypes";

// Fetch competitions
export const fetchCompetitionsStart = (params = {}) => {
  return {
    type: FETCH_COMPETITIONS_START,
    payload: params
  };
};

export const fetchCompetitionsSuccess = (data) => {
  return {
    type: FETCH_COMPETITIONS_SUCCESS,
    payload: data
  };
};

export const fetchCompetitionsFail = (error) => {
  return {
    type: FETCH_COMPETITIONS_FAIL,
    payload: { error }
  };
};

// Add competition
export const addCompetitionStart = (competition) => {
  return {
    type: ADD_COMPETITION_START,
    payload: { competition }
  };
};

export const addCompetitionSuccess = (data) => {
  return {
    type: ADD_COMPETITION_SUCCESS,
    payload: data
  };
};

export const addCompetitionFail = (error) => {
  return {
    type: ADD_COMPETITION_FAIL,
    payload: { error }
  };
};

export const editCompetitionStart = (competition) => ({
  type: EDIT_COMPETITION_START,
  payload: competition,
});

export const editCompetitionSuccess = (response) => ({
  type: EDIT_COMPETITION_SUCCESS,
  payload: response,
});

export const editCompetitionFail = (error) => ({
  type: EDIT_COMPETITION_FAIL,
  payload: error,
});

export const editFormDataCompetition = (formData) => ({
  type: EDIT_FORM_DATA_COMPETITION,
  payload: formData,
});


// Delete competition
export const deleteCompetitionStart = (id) => {
  return {
    type: DELETE_COMPETITION_START,
    payload: { id }
  };
};

export const deleteCompetitionSuccess = (id) => {
  return {
    type: DELETE_COMPETITION_SUCCESS,
    payload: { id }
  };
};

export const addFormDataCompetition = (competition)=>{
  return {
    type: ADD_FORM_COMPETITION_START,
    payload:{ competition }
  };
};
export const deleteCompetitionFail = (error) => {
  return {
    type: DELETE_COMPETITION_FAIL,
    payload: { error }
  };
};

// Clear error
export const errorClear = () => {
  return {
    type: ERROR_CLEAR
  };
};