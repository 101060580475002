// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import {
//   useDispatch,
//   connect,
//   useSelector
// } from "react-redux";
// import {
//   Modal,
//   Button,
//   ModalHeader,
//   ModalBody,
//   UncontrolledAlert,
//   Col,
//   Row,
//   Label,
// } from "reactstrap";
// import { AvForm, AvField } from "availity-reactstrap-validation";

// import AvFieldSelect from "components/Common/AvFieldSelect";
// import { MONTHS } from "common/data/dropdowns";
// import { validateFile } from "pages/Transactions/Crypto/AddDepositForm";
// import { addCompetitionStart, addFormDataCompetition } from "store/ibCompetition/actions";
// import Loader from "components/Common/Loader";

// function AddCompetition(props) {
//   const [addModal, setAddModal] = useState(false);
//   const dispatch = useDispatch();

//   const [prizes, setPrizes] = useState([""]); // Prize fields
//   const [images, setImages] = useState([]); // image file inputs


//   const handleSubmit = (e, v) => {
//     e.preventDefault();
//     delete v.prizes;
//     delete v.images;
//     if (images.length > 0) {
//       const formData = new FormData();
//       formData.append("address", v.address);
//       formData.append("info", v.info);
//       formData.append("competitionDate", v.competitionDate);
//       images.forEach((image) => {
//         formData.append("images", image);
//       });
//       formData.append("prizes", prizes);
//       dispatch(addFormDataCompetition(formData));
//     } else {
//       dispatch(addCompetitionStart({
//         ...v,
//         prizes,
//       }));
//     }
//   };

//   // Function to add a new prize field
//   const addPrizeField = () => {
//     setPrizes([...prizes, ""]);
//   };

//   // Function to remove a prize field
//   const removePrizeField = (index) => {
//     const newPrizes = prizes.filter((_, i) => i !== index);
//     setPrizes(newPrizes);
//   };

//   // Handle change for each prize field
//   const handlePrizeChange = (index, value) => {
//     const newPrizes = [...prizes];
//     newPrizes[index] = value;
//     setPrizes(newPrizes);
//   };

//   // Function to add new file input field
//   const addImageField = () => {
//     if (images.length < 4) {
//       setImages([...images, null]); 
//     }
//   };

//   // Function to remove a file input field
//   const removeImageField = (index) => {
//     setImages(images.filter((_, i) => i !== index));
//   };

//   // Handle file selection
//   const handleFileChange = (index, file) => {
//     const newImages = [...images];
//     newImages[index] = file;
//     setImages(newImages);
//   };

//   const toggleAddModal = () => {
//     setAddModal(!addModal);
//   };

//   useEffect(() => {
//     // if (props.clearingCounter > 0 && addModal) {
//     if (props.addSuccess && addModal) {
//       setAddModal(false);
//     }
//   }, [props.addSuccess]);

//   return (
//     <React.Fragment>
//       <Link to="#" className={"btn btn-primary"} onClick={toggleAddModal}>
//         <i className="bx bx-plus me-1"></i> Add Competition
//       </Link>
//       <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
//         <ModalHeader toggle={toggleAddModal} tag="h4">
//           Add Competition
//         </ModalHeader>
//         <ModalBody>
//           <AvForm className="p-4" onValidSubmit={(e, v) => { handleSubmit(e, v) }}>
//             <div className="mb-3">
//               <AvField
//                 name="address"
//                 label="Competition Address"
//                 placeholder="Enter Competition Address"
//                 type="text"
//                 errorMessage="Enter Competition Address"
//                 validate={{ required: { value: true } }}
//               />
//             </div>

//             <div className="mb-3">
//               <AvField
//                 className="form-control"
//                 name="info"
//                 label="Competition Information"
//                 type="textarea"
//                 rows="5"
//                 placeholder="Enter Competition Information"
//                 validate={{ required: { value: true } }}
//                 style={{ resize: "none" }}
//               />
//             </div>

//             <div className="mb-3">
//               <AvFieldSelect
//                 name="competitionDate"
//                 type="text"
//                 errorMessage="Competition Date is required"
//                 validate={{ required: { value: true } }}
//                 label="Competition Date"
//                 options={MONTHS.map((obj) => ({
//                   label: obj,
//                   value: obj
//                 }))}
//               />
//             </div>

//             <div>
//               <div className="d-flex justify-content-between align-items-center">
//                 <Label>Prizes</Label>
//                 <Button type="button" color="primary" onClick={addPrizeField} className="mt-2">
//                 Add Prize
//                 </Button>
//               </div>
//               {prizes.map((prize, index) => (
//                 <Row key={index} className="mb-2 align-items-center">
//                   <Col md={9}>
//                     <AvField
//                       name={"prizes"}
//                       label={`Prize ${index + 1}`}
//                       type="text"
//                       value={prize}
//                       onChange={(e) => handlePrizeChange(index, e.target.value)}
//                       required
//                     />
//                   </Col>
//                   <Col md={3}>
//                     <i className="bx bx-x-circle text-danger cursor-pointer mt-3" style={{ fontSize: "1.5rem" }} onClick={() => removePrizeField(index)}  />
//                   </Col>
//                 </Row>
//               ))}
//             </div>

//             <div>
//               <div className="d-flex justify-content-between align-items-center">
//                 <Label>Images</Label>
//                 {images.length < 4 && (
//                   <Button type="button" color="primary" onClick={addImageField} className="mt-2">
//                     Add Image
//                   </Button>
//                 )}
//               </div>

//               {images.map((image, index) => (
//                 <Row key={index} className="mb-3 align-items-center">
//                   <Col md="9">
//                     <Label className="form-label" htmlFor={`image${index}`}>
//                       {`Image ${index + 1}`}
//                     </Label>
//                     <AvField
//                       type="file"
//                       name={"images"}
//                       className="form-control form-control-md"
//                       errorMessage="Image is required"
//                       onChange={(e) => handleFileChange(index, e.target.files[0])}
//                       validate={{
//                         validate: validateFile(["jpg", "jpeg", "png"], 1000000, image, {
//                           sizeValidationMessage: "The file size is too large",
//                           extensionValidationMessage: "The file extension is not allowed",
//                         })
//                       }}
//                     />
//                   </Col>
//                   <Col md="3">
//                     <i className="bx bx-x-circle text-danger cursor-pointer mt-3" style={{ fontSize: "1.5rem" }} onClick={() => removeImageField(index)}  />
//                   </Col>
//                 </Row>
//               ))}

//             </div>

//             <div className="text-center">
//               {
//                 !props.submitting ?
//                   <Button type="submit" disabled= {props.submitting} color="primary">
//                     Add
//                   </Button>
//                   :
//                   <Loader />
//               }
//             </div>
//           </AvForm>

//           {props.addError && (
//             <UncontrolledAlert color="danger">
//               <i className="mdi mdi-block-helper me-2"></i>
//               {props.addErrorDetails}
//             </UncontrolledAlert>
//           )}
//         </ModalBody>
//       </Modal>
//     </React.Fragment>
//   );
// }

// const mapStateToProps = (state) => ({
//   submitting: state.competitionReducer.submitting,
//   addErrorDetails: state.competitionReducer.addErrorDetails,
//   addSuccess: state.competitionReducer.addSuccess,
//   addError: state.competitionReducer.addError,
//   clearingCounter: state.competitionReducer.clearingCounter,
//   teamsPermissions: state.Profile.teamsPermissions || {}
// });

// export default connect(mapStateToProps, null)(AddCompetition);


import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  useDispatch,
  connect,
  useSelector
} from "react-redux";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Col,
  Row,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import AvFieldSelect from "components/Common/AvFieldSelect";
import { MONTHS } from "common/data/dropdowns";
import { validateFile } from "pages/Transactions/Crypto/AddDepositForm";
import { addCompetitionStart, addFormDataCompetition } from "store/ibCompetition/actions";
import Loader from "components/Common/Loader";

function AddCompetition(props) {
  const [addModal, setAddModal] = useState(false);
  const dispatch = useDispatch();

  const [prizes, setPrizes] = useState([""]); // Prize fields
  const [images, setImages] = useState([]); // image file inputs


  const handleSubmit = (e, v) => {
    e.preventDefault();
    delete v.prizes;
    delete v.images;
    if (images.length > 0) {
      const formData = new FormData();
      formData.append("address", v.address);
      formData.append("info", v.info);
      formData.append("competitionDate", v.competitionDate);
      formData.append("competitionYear", v.competitionYear);
      images.forEach((image) => {
        formData.append("images", image);
      });
      formData.append("prizes", prizes);
      dispatch(addFormDataCompetition(formData));
    } else {
      dispatch(addCompetitionStart({
        ...v,
        prizes,
      }));
    }
  };

  // Function to add a new prize field
  const addPrizeField = () => {
    setPrizes([...prizes, ""]);
  };

  // Function to remove a prize field
  const removePrizeField = (index) => {
    const newPrizes = prizes.filter((_, i) => i !== index);
    setPrizes(newPrizes);
  };

  // Handle change for each prize field
  const handlePrizeChange = (index, value) => {
    const newPrizes = [...prizes];
    newPrizes[index] = value;
    setPrizes(newPrizes);
  };

  // Function to add new file input field
  const addImageField = () => {
    if (images.length < 4) {
      setImages([...images, null]); 
    }
  };

  // Function to remove a file input field
  const removeImageField = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  // Handle file selection
  const handleFileChange = (index, file) => {
    const newImages = [...images];
    newImages[index] = file;
    setImages(newImages);
  };

  const toggleAddModal = () => {
    setAddModal(!addModal);
  };

  // Generate years for dropdown (current year and 5 years ahead)
  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i < 10; i++) {
      const year = currentYear + i;
      years.push({
        label: year.toString(),
        value: year.toString() 
      });
    }
    return years;
  };

  useEffect(() => {
    // if (props.clearingCounter > 0 && addModal) {
    if (props.addSuccess && addModal) {
      setAddModal(false);
    }
  }, [props.addSuccess]);

  return (
    <React.Fragment>
      <Link to="#" className={"btn btn-primary"} onClick={toggleAddModal}>
        <i className="bx bx-plus me-1"></i> Add Competition
      </Link>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          Add Competition
        </ModalHeader>
        <ModalBody>
          <AvForm className="p-4" onValidSubmit={(e, v) => { handleSubmit(e, v) }}>
            <div className="mb-3">
              <AvField
                name="address"
                label="Competition Address"
                placeholder="Enter Competition Address"
                type="text"
                errorMessage="Enter Competition Address"
                validate={{ required: { value: true } }}
              />
            </div>

            <div className="mb-3">
              <AvField
                className="form-control"
                name="info"
                label="Competition Information"
                type="textarea"
                rows="5"
                placeholder="Enter Competition Information"
                validate={{ required: { value: true } }}
                style={{ resize: "none" }}
              />
            </div>

            <div className="mb-3">
              <AvFieldSelect
                name="competitionDate"
                type="text"
                errorMessage="Competition Date is required"
                validate={{ required: { value: true } }}
                label="Competition Date"
                options={MONTHS.map((obj) => ({
                  label: obj,
                  value: obj
                }))}
              />
            </div>

            <div className="mb-3">
              <AvFieldSelect
                name="competitionYear"
                type="text"
                errorMessage="Competition Year is required"
                validate={{ required: { value: true } }}
                label="Competition Year"
                options={generateYearOptions()}
              />
            </div>

            <div>
              <div className="d-flex justify-content-between align-items-center">
                <Label>Prizes</Label>
                <Button type="button" color="primary" onClick={addPrizeField} className="mt-2">
                Add Prize
                </Button>
              </div>
              {prizes.map((prize, index) => (
                <Row key={index} className="mb-2 align-items-center">
                  <Col md={9}>
                    <AvField
                      name={"prizes"}
                      label={`Prize ${index + 1}`}
                      type="text"
                      value={prize}
                      onChange={(e) => handlePrizeChange(index, e.target.value)}
                      required
                    />
                  </Col>
                  <Col md={3}>
                    <i className="bx bx-x-circle text-danger cursor-pointer mt-3" style={{ fontSize: "1.5rem" }} onClick={() => removePrizeField(index)}  />
                  </Col>
                </Row>
              ))}
            </div>

            <div>
              <div className="d-flex justify-content-between align-items-center">
                <Label>Images</Label>
                {images.length < 4 && (
                  <Button type="button" color="primary" onClick={addImageField} className="mt-2">
                    Add Image
                  </Button>
                )}
              </div>

              {images.map((image, index) => (
                <Row key={index} className="mb-3 align-items-center">
                  <Col md="9">
                    <Label className="form-label" htmlFor={`image${index}`}>
                      {`Image ${index + 1}`}
                    </Label>
                    <AvField
                      type="file"
                      name={"images"}
                      className="form-control form-control-md"
                      errorMessage="Image is required"
                      onChange={(e) => handleFileChange(index, e.target.files[0])}
                      validate={{
                        validate: validateFile(["jpg", "jpeg", "png"], 1000000, image, {
                          sizeValidationMessage: "The file size is too large",
                          extensionValidationMessage: "The file extension is not allowed",
                        })
                      }}
                    />
                  </Col>
                  <Col md="3">
                    <i className="bx bx-x-circle text-danger cursor-pointer mt-3" style={{ fontSize: "1.5rem" }} onClick={() => removeImageField(index)}  />
                  </Col>
                </Row>
              ))}

            </div>

            <div className="text-center">
              {
                !props.submitting ?
                  <Button type="submit" disabled= {props.submitting} color="primary">
                    Add
                  </Button>
                  :
                  <Loader />
              }
            </div>
          </AvForm>

          {props.addError && (
            <UncontrolledAlert color="danger">
              <i className="mdi mdi-block-helper me-2"></i>
              {props.addErrorDetails}
            </UncontrolledAlert>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  submitting: state.competitionReducer.submitting,
  addErrorDetails: state.competitionReducer.addErrorDetails,
  addSuccess: state.competitionReducer.addSuccess,
  addError: state.competitionReducer.addError,
  clearingCounter: state.competitionReducer.clearingCounter,
  teamsPermissions: state.Profile.teamsPermissions || {}
});

export default connect(mapStateToProps, null)(AddCompetition);