
import {
  call,
  put,
  takeEvery,
  takeLatest,
  delay
} from "redux-saga/effects";
import { 
  FETCH_COMPETITIONS_START,
  ADD_COMPETITION_START,
  DELETE_COMPETITION_START,
  ADD_FORM_COMPETITION_START,
  EDIT_COMPETITION_START,
  EDIT_FORM_DATA_COMPETITION
} from "./actionTypes";
import {
  fetchCompetitionsSuccess,
  fetchCompetitionsFail,
  addCompetitionSuccess,
  addCompetitionFail,
  deleteCompetitionSuccess,
  deleteCompetitionFail,
  errorClear,
  editCompetitionSuccess,
  editCompetitionFail,
  editFormDataCompetition,
  editCompetitionStart
} from "./actions";
import {
  getCompetitions,
  createCompetition,
  deleteCompetition,
  createFormDataCompetition,
  updateCompetition,
  updateFormDataCompetition
} from "apis/ibCompetition";
import { showSuccessNotification, showErrorNotification } from "store/notifications/actions";

function* fetchCompetitions({ payload }) {
  try {
    const data = yield call(getCompetitions, payload);
    if (data.status) {
      yield put(fetchCompetitionsSuccess(data));
    } else {
      yield put(fetchCompetitionsFail(data.message || "Failed to fetch competitions"));
      yield put(showErrorNotification(data.message || "Failed to fetch competitions"));
    }
  } catch (error) {
    yield put(fetchCompetitionsFail(error.message || "Error occurred while fetching competitions"));
    yield put(showErrorNotification(error.message || "Error occurred while fetching competitions"));
  }
}

function* addCompetition({ payload: { competition } }) {
  try {
    const data = yield call(createCompetition, competition);
    console.log("dataaaa", data);
    if (data.status) {
      yield put(addCompetitionSuccess(data.result));
      yield put(showSuccessNotification("Competition created successfully"));
    } else {
      yield put(addCompetitionFail(data.message || "Failed to create competition"));
      yield put(showErrorNotification(data.message || "Failed to create competition"));
    }
  } catch (error) {
    yield put(addCompetitionFail(error.message || "Error occurred while creating competition"));
    yield put(showErrorNotification(error.message || "Error occurred while creating competition"));
    yield delay(5000);
    yield put(errorClear());
  }
}

function* removeCompetition({ payload: { id } }) {
  try {
    console.log("delete Com", id);  
    const data = yield call(deleteCompetition, id);
    
    console.log("delete Comdataaaa", data);  
    if (data.status) {
      yield put(deleteCompetitionSuccess(id));
      yield put(showSuccessNotification("Competition deleted successfully"));
    } else {
      yield put(deleteCompetitionFail(data.message || "Failed to delete competition"));
      yield put(showErrorNotification(data.message || "Failed to delete competition"));
    }
  } catch (error) {
    yield put(deleteCompetitionFail(error.message || "Error occurred while deleting competition"));
    yield put(showErrorNotification(error.message || "Error occurred while deleting competition"));
    yield delay(5000);
    yield put(errorClear());
  }
}

function * addFormDataCompetition({ payload:{ competition } }){
  try {
    const data = yield call(createFormDataCompetition, competition);
    const { status, result } = data;
    console.log("dataaaa", data);
    if (data.status) {
      yield put(addCompetitionSuccess(data.result));
      yield put(showSuccessNotification("Competition created successfully"));
    } else {
      yield put(addCompetitionFail(data.message || "Failed to create competition"));
      yield put(showErrorNotification(data.message || "Failed to create competition"));
    }
  } catch (error) {
    yield put(addCompetitionFail(error.message || "Error occurred while creating competition"));
    yield put(showErrorNotification(error.message || "Error occurred while creating competition"));
    yield delay(5000);
    yield put(errorClear());
  }
  //   if (status === true){
  //     // yield put(addDepositSuccess(result));
  //     // yield put(modalClear());
  //     yield put(addCompetitionSuccess(data.result));
  //     yield put(showSuccessNotification(`Competition has been Addedd Successfully`));
  //   }
  // } catch (error){
  //   yield put(deleteCompetitionFail(error.message || "Error occurred while deleting competition"));
  //   yield delay(5000);
  //   yield put(errorClear());
  // } 
}


function* onEditCompetition({ payload }) {
  try {
    const response = yield call(updateCompetition, payload.id, payload);
    yield put(editCompetitionSuccess(response));
    yield put(showSuccessNotification("Competition Updated successfully"));
  } catch (error) {
    yield put(editCompetitionFail(error.response?.data?.message || "Edit failed"));
    yield put(showErrorNotification(error.message || "Error occurred while Updateing competition"));
    yield put(errorClear());
  }
}

function* onEditFormDataCompetition({ payload }) {
  try {
    const response = yield call(updateFormDataCompetition, payload.get("id"), payload);
    yield put(editCompetitionSuccess(response));
    yield put(showSuccessNotification("Competition Updated successfully"));
  } catch (error) {
    yield put(editCompetitionFail(error.response?.data?.message || "Edit failed"));
    yield put(showErrorNotification(error.message || "Error occurred while Updateing competition"));
    yield put(errorClear());
  }
}

function* competitionSaga() {
  yield takeEvery(EDIT_COMPETITION_START, onEditCompetition);
  yield takeEvery(EDIT_FORM_DATA_COMPETITION, onEditFormDataCompetition);
  yield takeLatest(FETCH_COMPETITIONS_START, fetchCompetitions);
  yield takeLatest(ADD_COMPETITION_START, addCompetition);
  yield takeEvery(ADD_FORM_COMPETITION_START, addFormDataCompetition);
  yield takeLatest(DELETE_COMPETITION_START, removeCompetition);
}

export default competitionSaga;